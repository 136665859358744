@keyframes show {
  0% {
    transform: translateX(-100%) translateZ(0);
  }
  99% {
    transform: translateX(0%) translateZ(0);
  }
  100% {
    transform: translateX(0%) translateZ(0);
    visibility: visible;
  }
}

@keyframes hide {
  0% {
    transform: translateX(0%) translateZ(0);
  }
  99% {
    transform: translateX(-100%) translateZ(0);
  }
  100% {
    transform: translateX(-100%) translateZ(0);
    visibility: hidden;
  }
}

.menuDrawer {
  background: var(--ds-color-neutral-white);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 3;
  max-width: 100%;
  transform: translateX(-100%) translateZ(0);
  visibility: hidden;
  display: flex;
  flex-direction: column;

  @media (--ds-breakpoint-sm) {
    max-width: 375px;
    overflow-x: hidden;
  }
}

.show {
  animation: 0.3s show;
  animation-fill-mode: forwards;
}

.hide {
  animation: 0.3s hide;
  animation-fill-mode: forwards;
}

.panelsWrapper {
  flex-grow: 1;
  position: relative;
}
